

$ = require 'jquery'
require 'fullpage.js'

$(document).ready ->
	$('#landing').fullpage(
		anchors: ['home', 'chi-sono', 'servizi', 'lo-studio', 'contatti']
		menu: '.menu'
		afterLoad: (anchorLink, index) ->
			if anchorLink is 'home'

				if $('.header.is-active').size()
					$('.header').removeClass 'is-active'

			else
				$('.header').addClass 'is-active'
	)
